<template>
  <div>
    <v-form>
      <div class="flex">
        <v-text-field
          v-model="question.text"
          label="Question"
          style="margin-right: 8px; flex: 3 1 0"
          outlined
        ></v-text-field>
        <v-file-input
          v-model="question.image"
          accept="image/*"
          label="Question Image"
          prepend-icon=""
          style="flex: 2 1 0"
          outlined
          :clearable="false"
        ></v-file-input>
      </div>
      <div class="flex">
        <v-select
          v-model="selectedCategory"
          :items="categories"
          :menu-props="{ maxHeight: '400' }"
          item-value="_id"
          item-text="name"
          label="Category"
          hint="Pick question category"
          outlined
          persistent-hint
          style="margin-right: 8px; flex: 3 1 0"
        ></v-select>
        <div class="flex" style="flex: 2 1 0">
          <v-text-field
            v-model="newCategory"
            label="Category Name"
            style="margin-right: 8px"
            outlined
          />
          <v-btn
            color="green"
            fab
            small
            style="margin-top: 6px"
            :disabled="!newCategory"
            @click="addCategory"
          >
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <div>
        <v-btn
          block
          elevation="3"
          color="primary"
          large
          style="margin-bottom: 24px"
          @click="addChoice"
        >
          Add Choice
        </v-btn>
      </div>
      <v-radio-group v-model="rightAnswer">
        <div v-for="c in choiceCount" :key="c" class="flex">
          <v-radio :value="c - 1" style="margin: 0px 8px 24px" />
          <v-text-field
            v-model="choices[c - 1].text"
            :label="`Choice ${c} Text`"
            style="margin-right: 8px; flex: 3 1 0"
            outlined
          ></v-text-field>
          <v-file-input
            v-model="choices[c - 1].image"
            accept="image/*"
            :label="`Choice ${c} Image`"
            prepend-icon=""
            style="margin-right: 8px; flex: 2 1 0"
            outlined
            :clearable="false"
          ></v-file-input>
          <v-btn
            color="red"
            fab
            small
            style="margin-top: 6px"
            @click="deleteChoice(c - 1)"
          >
            <v-icon color="white">mdi-minus</v-icon>
          </v-btn>
        </div>
      </v-radio-group>
      <v-btn
        block
        elevation="3"
        :disabled="!valid"
        color="primary"
        large
        @click="createQuestion"
      >
        CREATE
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import axios from '@axios';

export default {
  name: 'QuestionCreate',
  components: {},
  data: () => ({
    newCategory: '',
    choiceCount: 0,
    choices: [],
    categories: [],
    rightAnswer: null,
    selectedCategory: null,
    question: {
      text: '',
      image: []
    }
  }),
  computed: {
    valid() {
      if (!this.question.text && this.question.image.length === 0) return false;
      for (const choice of this.choices) {
        if (!choice.text && choice.image.length === 0) return false;
      }
      if (!this.selectedCategory) return false;
      return true;
    }
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    convertFileToBase64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => resolve(null);
      });
    },
    getCategories() {
      axios
        .get('/list-question-category')
        .then((response) => {
          if (response?.data?.result) {
            this.categories = response.data.result;
          }
        })
        .catch(() => {
          this.$toast.error('Error while getting question categories');
        });
    },
    addCategory() {
      axios
        .post('/create-question-category', {
          name: this.newCategory
        })
        .then(() => {
          this.$toast.success('Successfully added category');
          this.newCategory = '';
          this.getCategories();
        })
        .catch(() => {
          this.$toast.error('Unexpected error');
        });
    },
    addChoice() {
      this.choices.push({
        text: '',
        image: []
      });
      this.choiceCount++;
    },
    deleteChoice(index) {
      this.choices.splice(index, 1);
      this.choiceCount--;
    },
    createQuestion() {
      const formData = new FormData();

      formData.append('categoryId', this.selectedCategory);
      formData.append('questionText', this.question.text);
      formData.append(
        'rightAnswer',
        String.fromCharCode(97 + this.rightAnswer)
      );

      const questionImage = this.question.image;
      formData.append('questionImage', questionImage);

      const choicesJson = [];
      let charCode = 65;
      for (let i = 0; i < this.choices.length; i++) {
        const c = this.choices[i];
        const data = {
          name: String.fromCharCode(charCode++),
          text: c.text
        };
        if (c.image?.length !== 0) {
          data.imageKey = `choiceImage${i + 1}`;
          formData.append(`choiceImage${i + 1}`, c.image);
        }
        choicesJson.push(data);
      }

      formData.append('choices', JSON.stringify(choicesJson));

      axios
        .post('/create-question', formData, {
          'Content-Type': 'multipart/form-data'
        })
        .then(() => {
          this.$toast.success('Successfully added question');
        })
        .catch(() => {
          this.$toast.error('Unexpected error');
        });
    }
  }
};
</script>

<style lang="scss"></style>
