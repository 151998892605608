<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="50"
      :loading="isLoading"
      item-key="_id"
      multi-sort
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialogEdit" max-width="750px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Soru Düzenleme</span>
              </v-card-title>
              <v-card-text>
                <v-container class="grey lighten-5">
                  <v-row>
                    <v-col md="12" lg="12" xl="12">
                      <v-card class="pa-1" outlined shaped>
                        <v-card-title>Soru Bilgileri</v-card-title>
                        <v-card-text>
                          <v-text-field
                            v-if="editedItem.question"
                            v-model="editedItem.question.text"
                            label="Soru İsmi"
                            outlined
                          >
                          </v-text-field>
                          <v-col>
                            <v-row
                              v-for="data in editedItem.choices"
                              :key="data.name"
                              cols="6"
                              md="3"
                              class="d-flex align-center"
                            >
                              <v-text-field
                                v-model="data.text"
                                :label="`Cevap ${data.name}`"
                                outlined
                              ></v-text-field>
                            </v-row>
                          </v-col>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDialogEdit">
                  İptal Et
                </v-btn>
                <v-btn color="green darken-1" text @click="saveDialogEdit">
                  Kaydet
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Silmek İstediğinizden Emin Misiniz ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDelete"
                  >İptal Et</v-btn
                >
                <v-btn color="red darken-1" text @click="deleteItemConfirm"
                  >Evet</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.question.image`]="{ item }">
        <img
          v-if="item.question.image"
          :src="`${imageBaseUrl}${item.question.image}`"
          width="25%"
          object-fit="cover"
        />
        <span v-else>x</span>
      </template>
      <template v-slot:[`item.choices`]="{ item }">
        <span v-for="(next, i) in item.choices" :key="i">
          <span v-if="next.image">
            {{ next.name }} :
            <img
              :src="`${imageBaseUrl}${next.image}`"
              :alt="`${imageBaseUrl}${next.image}`"
              width="40%"
              object-fit="cover"
            />
          </span>
          <span v-else> {{ next.name }} : {{ next.text }} </span>
          <span v-if="i != Object.keys(item.choices).length - 1"><br /> </span>
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.isActive"
          small
          class="mr-2"
          @click="passivateItem(item)"
        >
          mdi-check-bold
        </v-icon>
        <v-icon v-else small class="mr-2" @click="activateItem(item)">
          mdi-close-thick
        </v-icon>
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from '@axios';

export default {
  name: 'QuestionList',

  components: {},
  props: {
    update: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data: () => ({
    imageBaseUrl:
      'https://tansug-space.fra1.cdn.digitaloceanspaces.com/taleon-content/',
    isLoading: true,
    dialogDelete: false,
    dialogEdit: false,
    selectedDeleteItemId: '',
    editedItem: {},
    headers: [
      {
        text: 'Soru Resmi',
        align: 'center',
        value: 'question.image',
        width: '25%'
      },
      {
        text: 'Soru Yazısı',
        align: 'start',
        value: 'question.text',
        width: '35%'
      },
      {
        text: 'Kategori',
        align: 'start',
        value: 'category.name'
      },
      {
        text: 'Şıklar',
        align: 'start',
        value: 'choices'
      },
      {
        text: 'Doğru Cevap',
        align: 'start',
        value: 'rightAnswer'
      },
      {
        text: 'İşlemler',
        value: 'actions',
        width: '100px',
        sortable: false
      }
    ],
    items: []
  }),
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdit(val) {
      val || this.closeDialogEdit();
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    list() {
      axios.get('/list-question').then((response) => {
        this.isLoading = false;
        const items = response.data.result;
        this.items = items;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      this.closeDelete();
      axios
        .post(`/delete-question/${this.selectedDeleteItemId}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Soru Silindi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    activateItem(item) {
      axios
        .post(`/activate-question/${item._id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Soru Aktif Edildi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    passivateItem(item) {
      axios
        .post(`/passivate-question/${item._id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Soru Pasif Edildi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    editItem(item) {
      this.dialogEdit = true;
      this.editedItem = item;
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedDeleteItemId = item._id;
    },
    saveDialogEdit() {
      axios
        .post(`/edit-question/${this.editedItem._id}`, {
          questionText: this.editedItem.question.text,
          choices: this.editedItem.choices
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Soru Güncellendi.');
            this.list();
            this.dialogEdit = false;
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    closeDialogEdit() {
      this.dialogEdit = false;
    }
  }
};
</script>

<style lang="scss"></style>
